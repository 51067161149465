const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

const baseCMS = 'cms/';

export default {

    auth : {
        login  : baseUrl + 'auth/login/',
        status : baseUrl + 'auth/status/'
    },

    enquiries : {
        list   : baseUrl + baseCMS + 'contact-us/list/',
        delete : baseUrl + baseCMS + 'contact-us/delete/'
    },

    cms : {
        aboutUs : {
            addEdit : baseUrl + baseCMS + 'about-us/create/',
            delete  : baseUrl + baseCMS + 'about-us/delete/',
            list    : baseUrl + baseCMS + 'about-us/list/',
            details : baseUrl + baseCMS + 'about-us/details/'
        },

        visionMission : {
            addEdit : baseUrl + baseCMS + 'our-vision-mission/create/',
            list    : baseUrl + baseCMS + 'our-vision-mission/list/',
            delete  : baseUrl + baseCMS + 'our-vision-mission/delete/',
            details : baseUrl + baseCMS + 'our-vision-mission/details/'
        },

        titleContents : {
            addEdit : baseUrl + baseCMS + 'title-and-content/create/',
            list    : baseUrl + baseCMS + 'title-and-content/list/',
            delete  : baseUrl + baseCMS + 'title-and-content/delete/',
            details : baseUrl + baseCMS + 'title-and-content/details/'
        },

        team : {
            addEdit : baseUrl + baseCMS + 'team/create/',
            list    : baseUrl + baseCMS + 'team/list/',
            delete  : baseUrl + baseCMS + 'team/delete/',
            details : baseUrl + baseCMS + 'team/details/'
        },

        testimonial : {
            addEdit : baseUrl + baseCMS + 'testimonial/create/',
            list    : baseUrl + baseCMS + 'testimonial/list/',
            delete  : baseUrl + baseCMS + 'testimonial/delete/',
            details : baseUrl + baseCMS + 'testimonial/details/'
        },

        featured_bank : {
            addEdit : baseUrl + baseCMS + 'client/create/',
            list    : baseUrl + baseCMS + 'client/list/',
            delete  : baseUrl + baseCMS + 'client/delete/',
            details : baseUrl + baseCMS + 'client/details/'
        },

        faq : {
            addEdit : baseUrl + baseCMS + 'faq/create/',
            list    : baseUrl + baseCMS + 'faq/list/',
            delete  : baseUrl + baseCMS + 'faq/delete/',
            details : baseUrl + baseCMS + 'faq/details/',

            answer : {
                addEdit : baseUrl + baseCMS + 'faq-answer/create/',
                list    : baseUrl + baseCMS + 'faq-answer/list/',
                delete  : baseUrl + baseCMS + 'faq-answer/delete/'
            },

            section : {
                addEdit : baseUrl + baseCMS + 'faq-section/create/',
                list    : baseUrl + baseCMS + 'faq-section/list/',
                delete  : baseUrl + baseCMS + 'faq-section/delete/',
                details : baseUrl + baseCMS + 'faq-section/details/'
            },

            sectionContent : {
                addEdit : baseUrl + baseCMS + 'faq-section-content/create/',
                list    : baseUrl + baseCMS + 'faq-section-content/list/',
                delete  : baseUrl + baseCMS + 'faq-section-content/delete/'
            }
        },

        contactUs : {
            addEdit : baseUrl + baseCMS + 'contactus-page/create/',
            list    : baseUrl + baseCMS + 'contactus-page/list/',
            delete  : baseUrl + baseCMS + 'contactus-page/delete/',
            details : baseUrl + baseCMS + 'contactus-page/detail/'
        },

        privacyPolicy : {
            addEdit              : baseUrl + baseCMS + 'privacy-policy/create/',
            list                 : baseUrl + baseCMS + 'privacy-policy/list/',
            delete               : baseUrl + baseCMS + 'privacy-policy/delete/',
            details              : baseUrl + baseCMS + 'privacy-policy/details/',
            privacyPolicyContent : {
                addEdit : baseUrl + baseCMS + 'privacy-policy-content/create/',
                list    : baseUrl + baseCMS + 'privacy-policy-content/list/',
                delete  : baseUrl + baseCMS + 'privacy-policy-content/delete/',
                details : baseUrl + baseCMS + 'privacy-policy-content/details/'
            }
        },

        termsCondition : {
            addEdit               : baseUrl + baseCMS + 'terms-and-conditions/create/',
            list                  : baseUrl + baseCMS + 'terms-and-conditions/list/',
            delete                : baseUrl + baseCMS + 'terms-and-conditions/delete/',
            details               : baseUrl + baseCMS + 'terms-and-conditions/details/',
            termsConditionContent : {
                addEdit : baseUrl + baseCMS + 'terms-condition-content/create/',
                list    : baseUrl + baseCMS + 'terms-condition-content/list/',
                delete  : baseUrl + baseCMS + 'terms-condition-content/delete/',
                details : baseUrl + baseCMS + 'terms-condition-content/details/'
            }
        },

        socialMedia : {
            addEdit : baseUrl + baseCMS + 'social-media/create/',
            list    : baseUrl + baseCMS + 'social-media/list/',
            delete  : baseUrl + baseCMS + 'social-media/delete/',
            details : baseUrl + baseCMS + 'social-media/details/'
        },

        banner : {
            addEdit : baseUrl + baseCMS + 'banner/create/',
            list    : baseUrl + baseCMS + 'banner/list/',
            delete  : baseUrl + baseCMS + 'banner/delete/',
            details : baseUrl + baseCMS + 'banner/details/'
        },

        office : {
            teamSelect : baseUrl + baseCMS + 'team/vue-select/',
            addEdit    : baseUrl + baseCMS + 'office/create/',
            list       : baseUrl + baseCMS + 'office/list/',
            delete     : baseUrl + baseCMS + 'office/delete/',
            details    : baseUrl + baseCMS + 'office/details/'
        },

        directory : {
            addEdit : baseUrl + baseCMS + 'directory/create/',
            list    : baseUrl + baseCMS + 'directory/list/',
            delete  : baseUrl + baseCMS + 'directory/delete/',
            details : baseUrl + baseCMS + 'directory/details/'
        },

        media : {
            blog : {
                addEdit : baseUrl + baseCMS + 'blog/create/',
                list    : baseUrl + baseCMS + 'blog/list/',
                delete  : baseUrl + baseCMS + 'blog/delete/',
                details : baseUrl + baseCMS + 'blog/details/'
            },

            news : {
                addEdit : baseUrl + baseCMS + 'news/create/',
                list    : baseUrl + baseCMS + 'news/list/',
                delete  : baseUrl + baseCMS + 'news/delete/',
                details : baseUrl + baseCMS + 'news/details/'
            },
            imageAddEdit : baseUrl + baseCMS + 'media-image/create/',
            imageList    : baseUrl + baseCMS + 'media-image/list/',
            imageDelete  : baseUrl + baseCMS + 'media-image/delete/'
        },

        howItWorks : {
            addEdit : baseUrl + baseCMS + 'how-it-works/create/',
            delete  : baseUrl + baseCMS + 'how-it-works/delete/',
            list    : baseUrl + baseCMS + 'how-it-works/list/'
        },

        ourFeatures : {
            addEdit : baseUrl + baseCMS + 'our-features/create/',
            delete  : baseUrl + baseCMS + 'our-features/delete/',
            list    : baseUrl + baseCMS + 'our-features/list/'
        },
        teamSocialMedia : {
            addEdit : baseUrl + baseCMS + 'team-social-media/create/',
            list    : baseUrl + baseCMS + 'team-social-media/list/',
            delete  : baseUrl + baseCMS + 'team-social-media/delete/',
            details : baseUrl + baseCMS + 'team-social-media/details/'
        }
    },

    masterData : {
        ContactVentura : {
            contactVenturaOptions : baseUrl + 'master-data/ventura-contact/vue-select/',
            addEdit               : baseUrl + 'master-data/ventura-contact/create/',
            list                  : baseUrl + 'master-data/ventura-contact/list/',
            delete                : baseUrl + 'master-data/ventura-contact/delete/'
        },
        RefundTemplate : {
            addEdit : baseUrl + 'master-data/auction-document-template/create/',
            list    : baseUrl + 'master-data/auction-template/list/',
            delete  : baseUrl + 'master-data/auction-template/delete/'
        },
        Country : {
            countryOptions : baseUrl + 'master-data/country/vue-select/',
            addEdit        : baseUrl + 'master-data/country/create/',
            list           : baseUrl + 'master-data/country/list/',
            delete         : baseUrl + 'master-data/country/delete/'
        },
        State : {
            addEdit : baseUrl + 'master-data/state/create/',
            list    : baseUrl + 'master-data/state/list/',
            delete  : baseUrl + 'master-data/state/delete/'
        },
        MediaCategory : {
            addEdit             : baseUrl + 'master-data/media-category/create/',
            list                : baseUrl + 'master-data/media-category/list/',
            newsCategoryOptions : baseUrl + 'master-data/news-category/vue-select/',
            blogCategoryOptions : baseUrl + 'master-data/blog-category/vue-select/',
            delete              : baseUrl + 'master-data/media-category/delete/'
        },
        District : {
            addEdit         : baseUrl + 'master-data/district/create/',
            list            : baseUrl + 'master-data/district/list/',
            delete          : baseUrl + 'master-data/district/delete/',
            stateOptions    : baseUrl + 'master-data/state/vue-select/',
            districtOptions : baseUrl + 'master-data/district/vue-select/'
        },
        PropertyTypes : {
            addEdit : baseUrl + 'master-data/property-type/create/',
            list    : baseUrl + 'master-data/property-type/list/',
            delete  : baseUrl + 'master-data/property-type/delete/'
        },
        PropertySubCategory : {
            addEdit                    : baseUrl + 'master-data/property-sub-category/create/',
            list                       : baseUrl + 'master-data/property-sub-category/list/',
            delete                     : baseUrl + 'master-data/property-sub-category/delete/',
            propertyTypeOptions        : baseUrl + 'master-data/property-type/vue-select/',
            propertySubCategoryOptions : baseUrl + 'master-data/property-sub-category/vue-select/'
        },
        userManual : {
            addEdit : baseUrl + baseCMS + 'user-manual/create/',
            list    : baseUrl + baseCMS + 'user-manual/list/',
            delete  : baseUrl + baseCMS + 'user-manual/delete/'
        },
        tutorial : {
            addEdit : baseUrl + baseCMS + 'tutorial/create/',
            list    : baseUrl + baseCMS + 'tutorial/list/',
            delete  : baseUrl + baseCMS + 'tutorial/delete/'
        }
    }
};
