import { render, staticRenderFns } from "./EditTestimonial.vue?vue&type=template&id=e13ec4fe&scoped=true&"
import script from "./EditTestimonial.vue?vue&type=script&lang=js&"
export * from "./EditTestimonial.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e13ec4fe",
  null
  
)

export default component.exports